#map { 
/*
	width: 80%;
	height: 80vh;
	height: 180px; 
	width: 400px;
	background-color: salmon;
*/
	
	
}

.leaflet-container {
	/*width: 80%;*/
	width: 100%;
	height: 80vh;
	border-radius: 10px;
}

#map-border {
	padding: 4px 4px 4px 4px;
	width: 80%;
	background-color: lightgray;
	border-radius: 10px;
}

#map-container {

	flex: 1;
/*
	display: flex;*/
	justify-content: center;
	align-items: center;

	/*
	width: 100%;
	height: 85vh;
	background-color: lightgrey;*/

}