.Tab {
  color: white;
}

#app-bar-style {
}

.right-align {
  margin-left: auto;
}

.App {
  text-align: center;
  /*background-color: aliceblue;*/
  /*background: linear-gradient(180deg, #0346cc 0%, rgb(0, 153, 255) 100%);*/
  background:  linear-gradient(180deg, #3f87a6, #ebf8e1, #f69d3c);
  /*color: whitesmoke;*/
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
