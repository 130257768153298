#main-container {
	margin: 8px 8px 8px 8px;
	/*padding: 8px 8px 8px 8px;*/
	padding: 4px 4px 4px 4px;

	/*display: flex;*/
	width: 80%;
	background-color: lightgray;
	border-radius: 10px;
/*
	*/
	/*width: 90%;*/
}

#main-page {
	color: salmon;
}

#defining-container {
	/*margin: 8px 8px 8px 8px;*/
	display: flex;
	justify-content: center;
	align-items: center;
}